import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Root } from './Root';
import { PlayersList } from './modules/players/pages/list';
import { EditPlayer } from './modules/players/pages/edit';
import { usePlayerStore } from './stores/playerStore';
import { useGameStore } from './stores/gameStore';
import { Home } from './pages/Home';
import { GamesList } from './modules/games/pages/list';
import { EditGameSettings } from './modules/games/pages/edit';
import { StartNewGame } from './modules/games/pages/start-new';
import { Playing } from './modules/games/pages/playing';
import { AddPlay } from './modules/games/pages/add-play';
import { SettingsList } from './modules/settings/pages/list';

import './firebase';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

// TODO: figure out how to setup routing in the modules themselves
const router = createBrowserRouter([
    {
      path: "*",
      Component: Root,
      children: [
        {
          index: true,
          Component: Home
        },
        {
          path: "players/*",
          children: [
            {
              index: true,
              Component: PlayersList
            },
            {
              path: 'edit/:id?',
              loader:({ params }) => {
                const players = usePlayerStore.getState().players;
                return players.find(p => p.id === params.id) || null;
              },
              Component: EditPlayer
            }
          ],
        },
        {
          path: "games/*",
          children: [
            {
              index: true,
              Component: GamesList
            },
            {
              path: 'edit/:id?',
              loader:({ params }) => {
                const games = useGameStore.getState().games;
                return games.find(p => p.id === params.id) || null;
              },
              Component: EditGameSettings
            },
            {
              path: 'playing/:id?',
              loader:({ params }) => {
                const games = useGameStore.getState().active;
                return games.find(p => p.playId === params.id) || null;
              },
              Component: Playing
            },
            {
              path: 'start-new',
              loader:({ params }) => {
                console.log(params);
                //const games = useGameStore.getState().games;
                //return games.find(p => p.id === params.id) || null;
                return null;
              },
              Component: StartNewGame
            },
            {
              path: 'add-play',
              Component: AddPlay
            }
          ],
        },
        {
          path: "settings/*",
          children: [
            {
              index: true,
              Component: SettingsList
            }
          ],
        }
      ]
    }
], {
  basename: baseUrl
});

root.render(
  <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
