
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Grid, TextField, Typography } from "@mui/material";

import { usePlayerStore } from "../../../../src/stores/playerStore";
import { useLoaderData, useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
  firstname: yup.string().required(),
  lastname: yup.string(),
  age: yup.number(),
});

export function EditPlayer() {

  const navigate = useNavigate();

  const addPlayer = usePlayerStore((state) => state.addPlayer);
  const updatePlayer = usePlayerStore((state) => state.updatePlayer);

  const player = useLoaderData() as Player | undefined;

  const formik = useFormik({
    initialValues: {
      firstname: player?.firstname,
      lastname: player?.lastname,
      age: player?.age
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if(player){
        updatePlayer({
          ...player,
          firstname: values.firstname!,
          lastname: values.lastname,
          age: values.age
        });
      } else {
        addPlayer({
          firstname: values.firstname,
          lastname: values.lastname,
          age: values.age
        });
      }

      navigate('/players');
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6" gutterBottom>
          { player ? 'Edit' : 'Add' } Player
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="firstname"
              name="firstname"
              label="First Name"
              margin="normal"
              value={formik.values.firstname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.firstname && Boolean(formik.errors.firstname)}
              helperText={formik.touched.firstname && formik.errors.firstname}
            />
            <TextField
              fullWidth
              id="lastname"
              name="lastname"
              label="Last Name"
              margin="normal"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={formik.touched.lastname && formik.errors.lastname}
            />
            <TextField
              fullWidth
              id="age"
              name="age"
              label="Age"
              type="number"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={formik.values.age}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.age && Boolean(formik.errors.age)}
              helperText={formik.touched.age && formik.errors.age}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button color="primary" variant="outlined" fullWidth type="submit">
              Save
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button color="error" variant="text" fullWidth onClick={() => navigate('/players')}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
