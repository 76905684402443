import { v4 as newId } from 'uuid';

export const defaultGames: Game[] = [
  { id: '1', name: 'Carcassonne', settings: {} },
  { id: '2', name: 'Ticket To Ride', settings: {} },
  {
    id: '3',
    name: 'Lost Cities',
    settings: {
      scoring: {
        groupBy: 'subtype',
        resources: {
          groupResourcesBy: 'type,subtype',
          settings: [
            { index: 0, id: newId(), type: 'multiplier', subtype: 'purple', description: 'Purple Wager Card' },
            { index: 1, id: newId(), type: 'multiplier', subtype: 'purple', description: 'Purple Wager Card' },
            { index: 2, id: newId(), type: 'multiplier', subtype: 'purple', description: 'Purple Wager Card' },
            { index: 3, id: newId(), type: 'value', subtype: 'purple', description: 'Purple 1', value: '1', valueType: 'number' },
            { index: 4, id: newId(), type: 'value', subtype: 'purple', description: 'Purple 2', value: '2', valueType: 'number' },
            { index: 5, id: newId(), type: 'value', subtype: 'purple', description: 'Purple 3', value: '3', valueType: 'number' },
            { index: 6, id: newId(), type: 'value', subtype: 'purple', description: 'Purple 4', value: '4', valueType: 'number' },
            { index: 7, id: newId(), type: 'value', subtype: 'purple', description: 'Purple 5', value: '5', valueType: 'number' },
            { index: 8, id: newId(), type: 'value', subtype: 'purple', description: 'Purple 6', value: '6', valueType: 'number' },
            { index: 9, id: newId(), type: 'value', subtype: 'purple', description: 'Purple 7', value: '7', valueType: 'number' },
            { index: 10, id: newId(), type: 'value', subtype: 'purple', description: 'Purple 8', value: '8', valueType: 'number' },
            { index: 11, id: newId(), type: 'value', subtype: 'purple', description: 'Purple 9', value: '9', valueType: 'number' },
            { index: 12, id: newId(), type: 'value', subtype: 'purple', description: 'Purple 10', value: '10', valueType: 'number' },
            { index: 13, id: newId(), type: 'multiplier', subtype: 'green', description: 'Green Wager Card' },
            { index: 14, id: newId(), type: 'multiplier', subtype: 'green', description: 'Green Wager Card' },
            { index: 15, id: newId(), type: 'multiplier', subtype: 'green', description: 'Green Wager Card' },
            { index: 16, id: newId(), type: 'value', subtype: 'green', description: 'Green 1', value: '1', valueType: 'number' },
            { index: 17, id: newId(), type: 'value', subtype: 'green', description: 'Green 2', value: '2', valueType: 'number' },
            { index: 18, id: newId(), type: 'value', subtype: 'green', description: 'Green 3', value: '3', valueType: 'number' },
            { index: 19, id: newId(), type: 'value', subtype: 'green', description: 'Green 4', value: '4', valueType: 'number' },
            { index: 20, id: newId(), type: 'value', subtype: 'green', description: 'Green 5', value: '5', valueType: 'number' },
            { index: 21, id: newId(), type: 'value', subtype: 'green', description: 'Green 6', value: '6', valueType: 'number' },
            { index: 22, id: newId(), type: 'value', subtype: 'green', description: 'Green 7', value: '7', valueType: 'number' },
            { index: 23, id: newId(), type: 'value', subtype: 'green', description: 'Green 8', value: '8', valueType: 'number' },
            { index: 24, id: newId(), type: 'value', subtype: 'green', description: 'Green 9', value: '9', valueType: 'number' },
            { index: 25, id: newId(), type: 'value', subtype: 'green', description: 'Green 10', value: '10', valueType: 'number' },
          ]
        },
        mutations: [
          {
            order: 1,
            groupBy:'subtype',
            mutate: (value: number) => {
              return value - 20;
            }
          }, {
            order: 2,
            groupBy:'subtype',
            mutate: (value: number, playerScoredResoures?: PlayerScoringResource[]) => {
              let multiplier = 1;
              playerScoredResoures
                ?.filter(res => res.type === 'multiplier')
                .forEach(() => multiplier++);
              return value * multiplier;
            }
          }
        ]
      }
    }
  },
];
