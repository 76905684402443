import { GoogleAuthProvider, UserCredential, getRedirectResult, signInWithPopup, signOut, getAuth } from 'firebase/auth';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { app } from "../firebase";

const provider = new GoogleAuthProvider();
const auth = getAuth(app);

interface AuthState {
  user: UserCredential | null;
  //signedIn: () => boolean;
  error: string;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

// let redirectedUser: any;

// getRedirectResult(auth)
//   .then((result) => {
//     debugger;
//     if(result) {
//       // This gives you a Google Access Token. You can use it to access Google APIs.
//       const credential = GoogleAuthProvider.credentialFromResult(result);
//       const token = credential?.accessToken;
//       console.log(token)

//       // The signed-in user info.
//       const user = result.user;
//       // IdP data available using getAdditionalUserInfo(result)
//       // ...
//       console.log(user)
//       redirectedUser = user;
//     }
//   }).catch((error) => {
//     // Handle Errors here.
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     // The email of the user's account used.
//     const email = error.customData.email;
//     // The AuthCredential type that was used.
//     const credential = GoogleAuthProvider.credentialFromError(error);
//     // ...
//     console.log(error)
//   });

// const useAuthStore = create<AuthStore>((set) => ({
//     isLoggedIn: auth.currentUser !== null,
//     loginError: '',
//     login: async (credentials: Credentials) => {
//         const { email, password } = credentials
//         await signInWithEmailAndPassword(auth, email, password)
//             .then()
//             .catch((e) => {
//                 set({loginError: e.message})
//             })
//     }
// }))

export const useAuthStore = create<AuthState>(
  (set, get) => ({
    user: null,
    //signedIn: () => get()?.user != null,
    error: '',
    login: async () => {
      try {
        const result = await signInWithPopup(auth, provider);
        set({ user: result })
      } catch(e: any) {
        set({ error: e.message })
      }
    },
    logout: async () => {
      try{
        await signOut(auth);
        set({ user: null });
      } catch(e: any) {
        set({ error: e.message })
      }
    },
  })
);
