import { Theme, useTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

interface UsePointsSectionProps {
  usePoints: boolean;
  setUsePoints: (val: boolean) => void;
  usePointsHighestOrLowest: string;
  setUsePointsHighestOrLowest: (val: string) => void;
}

export function UsePointsSection({ usePoints, setUsePoints, usePointsHighestOrLowest, setUsePointsHighestOrLowest }: UsePointsSectionProps) {
  const theme = useTheme();

  function getSwitchDisplayStyles(
    display: string,
    selectedValue: string,
    theme: Theme
  ) {
    return {
      fontWeight:
        selectedValue !== display
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <Grid item xs={12} sx={{ display: "inline-flex" }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={usePoints}
            onClick={() => setUsePoints(!usePoints)}
          />
        }
        label="Use Points for Winner"
        labelPlacement="end"
      />

      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          style={getSwitchDisplayStyles(
            "highest",
            usePointsHighestOrLowest,
            theme
          )}
        >
          Highest
        </Typography>
        <Switch
          checked={usePointsHighestOrLowest !== "highest"}
          onClick={() =>
            setUsePointsHighestOrLowest(
              usePointsHighestOrLowest === "highest"
                ? "lowest"
                : "highest"
            )
          }
        />
        <Typography
          style={getSwitchDisplayStyles(
            "lowest",
            usePointsHighestOrLowest,
            theme
          )}
        >
          Lowest
        </Typography>
      </Stack>
    </Grid>
  )
}
