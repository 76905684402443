export const update = (idObj: Id, objArr: Id[]) => {
  const idx = objArr.findIndex(p => p.id == idObj.id);
  return [
    ...objArr.slice(0, idx),
    idObj,
    ...objArr.slice(idx + 1)
  ];
}

export const remove = (idObj: Id, objArr: Id[]) => {
  return [...objArr.filter(p => p.id !== idObj.id)]
}

export const toLookup = (objects: Id[]) => {
  return objects.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  },{} as { [key: string]: Id});
}

export const groupBy: (items: any[], property: string) => { [key: string]: any[]} = (items: any[], property: string) => items.reduce((r, v, i, a, k = v[property]) => ((r[k] || (r[k] = [])).push(v), r), {})
