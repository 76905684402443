// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyByes48o2yL0Dmf1v7mmuzbI-DstWAkgx0",
  authDomain: "tabletop-sidekick.firebaseapp.com",
  projectId: "tabletop-sidekick",
  storageBucket: "tabletop-sidekick.appspot.com",
  messagingSenderId: "962482083555",
  appId: "1:962482083555:web:652c5bf496726bcd04d7d6",
  measurementId: "G-9WQJEZ16WY"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
