import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { featureFlags } from './settingsStore.init';

interface SettingsState {
  featureFlags: {
    custom_scoring_experimental: FeatureFlag;
  };
  setFeatureFlagEnabled: ({ flag, enabled }: { flag: FeatureFlag; enabled: boolean }) => void;
}

export const useSettingsStore = create(
  persist(
    subscribeWithSelector<SettingsState>((set) => ({
      featureFlags: featureFlags,
      setFeatureFlagEnabled: ({ flag, enabled }) => set((state) => ({
        featureFlags: {
          ...state.featureFlags,
          [flag.id]: { ...flag, enabled: enabled }
        }
      })),
    })),
    {
      name: 'tabletopsidekick_featureflags'
    }
  )
);
