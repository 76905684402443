import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

import { ThemeOptions } from '@mui/material/styles';

// export const themeOptions: ThemeOptions = {
//   palette: {
//     mode: 'dark',
//     primary: {
//       main: '#5e3ca6',
//     },
//     secondary: {
//       main: '#84a63c',
//     },
//   },
// };

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#0a6b63',
    },
    secondary: {
      main: '#f50057',
    },
  },
};

// A custom theme for this app
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#556cd6',
//     },
//     secondary: {
//       main: '#19857b',
//     },
//     error: {
//       main: red.A400,
//     },
//   },
//   components: {
//     // Name of the component
//     MuiLink: {
//       defaultProps: {
//         color: '#ffffff'
//       },
//     },
//   },
// });

const theme = createTheme(themeOptions);

export default theme;
