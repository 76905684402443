export const defaultPlayers: Player[] = [
  { id: '1', lastname: 'Snow', firstname: 'Jon', age: 14 },
  { id: '2', lastname: 'Lannister', firstname: 'Cersei', age: 31 },
  { id: '3', lastname: 'Lannister', firstname: 'Jaime', age: 31 },
  { id: '4', lastname: 'Stark', firstname: 'Arya', age: 11 },
  { id: '5', lastname: 'Targaryen', firstname: 'Daenerys', age: null },
  { id: '6', lastname: 'Melisandre', firstname: 'Fire', age: 150 },
  { id: '7', lastname: 'Clifford', firstname: 'Ferrara', age: 44 },
  { id: '8', lastname: 'Frances', firstname: 'Rossini', age: 36 },
  { id: '9', lastname: 'Roxie', firstname: 'Harvey', age: 65 },
];
