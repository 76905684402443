import IconButton from "@mui/material/IconButton";
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

interface UserDisplayProps {
  signedIn: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

export function UserDisplay(props: UserDisplayProps) {
  return props.signedIn ?
    <IconButton
      size="large"
      edge="end"
      color="inherit"
      aria-label="logout"
      onClick={props.logout}
    >
      <LogoutIcon />
    </IconButton> :
    <IconButton
      size="large"
      edge="end"
      color="inherit"
      aria-label="login"
      onClick={props.login}
    >
      <LoginIcon />
    </IconButton>
}
