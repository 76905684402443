import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export interface ListHeaderProps {
  label: string;
  handleAddClick: () => void;
}

export function ListHeader(props: ListHeaderProps) {

  return (
    <Box sx={{ display: 'flex', pb: 2 }}>
      <Typography variant="h4" sx={{ flex: 1, paddingLeft: 1, paddingTop: 1 }}>
        {props.label}
      </Typography>
      <IconButton color="primary" size="large" onClick={props.handleAddClick} >
        <AddIcon />
      </IconButton>
    </Box>
  );
}
