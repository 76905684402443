
import React from 'react';

import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useNavigate } from 'react-router-dom';
import { useSettingsStore } from '../stores/settingsStore';

export function Home(){
  const navigate = useNavigate();
  const custom_scoring_experimental_ff = useSettingsStore(state => state.featureFlags.custom_scoring_experimental);

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <nav aria-label="main mailbox folders">
      <List>
          { custom_scoring_experimental_ff?.enabled ?
            <ListItem>
              <ListItemButton onClick={() => {}}>
                <ListItemIcon>
                  <PlayCircleOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Start new Game" onClick={() => navigate('games/start-new')} />
              </ListItemButton>
            </ListItem>
            : null
          }
          <ListItem>
            <ListItemButton onClick={() => {}}>
              <ListItemIcon>
                <PlayCircleOutlineIcon />
              </ListItemIcon>
              <ListItemText primary="Add a Play" onClick={() => navigate('games/add-play')} />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
    </Box>
  );
}
