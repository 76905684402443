import React from 'react';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId, GridValueGetterParams } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { usePlayerStore } from '../../../stores/playerStore';
import { ListBox } from '../../../components/ListBox';
import { ListHeader } from '../../../components/ListHeader';


export function PlayersList() {

  const navigate = useNavigate();

  const players = usePlayerStore((state) => state.players);
  const removePlayerById = usePlayerStore((state) => state.removePlayerById);

  const addPlayerClick = () => {
    navigate('/players/edit');
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    removePlayerById(id.toString());
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'firstname',
      headerName: 'First name',
      flex: 1,
      editable: true,
    },
    {
      field: 'lastname',
      headerName: 'Last name',
      flex: 1,
      editable: true,
    },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      flex: 1,
      editable: true,
    },
    {
      field: 'fullname',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.firstname || ''} ${params.row.lastname || ''}`,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => navigate(`/players/edit/${id}`)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <ListBox>
        <ListHeader
          handleAddClick={addPlayerClick}
          label={'Players'}
        />
        <DataGrid
          rows={players}
          columns={columns}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </ListBox>
    </>
  );
}
