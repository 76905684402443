import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { v4 as uuidv4 } from 'uuid';
import { remove, update } from '../utils/array';
import { defaultPlayers } from './playerStore.init';

interface PlayerState {
  players: Player[];
  addPlayer: (player: Partial<Player>) => void;
  updatePlayer: (player: Player) => void;
  removePlayer: (player: Player) => void;
  removePlayerById: (id: string) => void;
  completePlayer: (player: Player) => void;
}

const playerFactory = (player: Partial<Player>): Player => ({
  ...player,
  id: uuidv4()
}) as Player;

export const usePlayerStore = create(
  persist(
    subscribeWithSelector<PlayerState>((set) => ({
      players: defaultPlayers,
      addPlayer: (player: Partial<Player>) => set((state) => ({ players: [...state.players, playerFactory(player)] })),
      updatePlayer: (player: Player) => set((state) => ({ players: update(player, state.players) as Player[] })),
      removePlayer: (player: Player) => set((state) => ({ players: remove(player, state.players) as Player[] })),
      removePlayerById: (id: string) => set((state) => ({ players: remove({ id }, state.players) as Player[] })),
      completePlayer: (player: Player) => set((state) => ({ players: update({ ...player, completed: true } as Player, state.players) as Player[] })),
    })),
    {
      name: 'tabletopsidekick_players'
    }
  )
);
