
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { v4 as newId } from 'uuid';
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Stack, Switch, TextField, Theme, Typography, useTheme } from "@mui/material";

import { useGameStore } from "../../../stores/gameStore";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePlayerStore } from '../../../stores/playerStore';
import { WinnerCalculatorService } from '../services/winner-calculator-service';
import { UsePointsSection } from '../components/add-play/use-points-section';
import { toLookup } from '../../../utils/array';

const validationSchema = yup.object({
  gameId: yup.string().required(),
  players: yup.array().of(yup.string()).required(), // TODO: how to require a single item in array?
  //winners: yup.array().of(yup.string()).required()
});

function getStyles(name: string, playerName: string[], theme: Theme) {
  return {
    fontWeight:
      playerName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function AddPlay() {
  const theme = useTheme();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const gameId = searchParams.get('game');

  const [usePoints, setUsePoints] = useState(false);
  const [usePointsHighestOrLowest, setUsePointsHighestOrLowest] = useState("highest");

  const games = useGameStore((state) => state.games);
  const gamesLookup = toLookup(games) as { [key: string]: Game};
  const getGameDisplayName = (gameId: string | undefined) => gameId ? gamesLookup[gameId].name : undefined;

  const addPlay = useGameStore((state) => state.addPlay);

  const players = usePlayerStore((state) => state.players);
  const playersLookup = toLookup(players) as { [key: string]: Player};
  const getPlayerDisplayName = (playerId: string) => playersLookup[playerId].firstname;

  const winnerCalculatorService = new WinnerCalculatorService();

  const formik = useFormik({
    initialValues: {
      gameId: gameId as string | undefined,
      players: [] as string[],
      winners: [] as string[],
      points: {} as { [player: string]: number },
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const playId = newId();
      const play: GamePlay = {
        playId,
        gameId: values.gameId!,
        playerIds: values.players
      };

      if (usePoints) {
        const winners = winnerCalculatorService.calculateByPoints(values.points, usePointsHighestOrLowest as ('highest' | 'lowest'));
        play.scoring = {
          winners: winners,
        };
      } else {
        play.scoring = {
          winners: values.winners,
        };
      }

      await addPlay(play);

      navigate('/games');
      //navigate('/games/stats/'+ play.gameId);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6" gutterBottom>
          Add a Play of a Game
        </Typography>
        <Grid container spacing={3}>
          {/* Game section */}
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              autoComplete
              clearOnBlur={false}
              options={games.map(game => game.id)}
              getOptionLabel={ gameId => getGameDisplayName(gameId)! }
              getOptionKey={ gameId => gameId }
              value={formik.values.gameId}
              inputValue={getGameDisplayName(formik.values.gameId)} // required to stop an infinite loop of re-renders
              onChange={(event: any, newValue: string | null) => {
                formik.setFieldValue("gameId", newValue);
              }}
              onInputChange={() => { formik.setTouched({ gameId: true }) }}
              onBlur={formik.handleBlur}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="Game"
                  error={formik.touched.gameId && Boolean(formik.errors.gameId)}
                  helperText={formik.touched.gameId && formik.errors.gameId}
                  variant="outlined"
                />
              }
            />
          </Grid>

          {/* Players section */}
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="add-play-players-select-label">Players</InputLabel>
              <Select
                labelId="add-play-players-select-label"
                id="add-play-players-select"
                name="players"
                label="Players"
                multiple
                fullWidth
                value={formik.values.players}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                renderValue={(selected) => selected?.map(getPlayerDisplayName).join(", ")}
              >
                {players.map((player) => (
                  <MenuItem
                    key={player.id}
                    value={player.id}
                    style={getStyles(player.firstname, formik.values.players, theme)}
                  >
                    <Checkbox checked={formik.values.players?.indexOf(player.id) > -1} />
                    <ListItemText primary={player.firstname} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Use Points section */}
          <UsePointsSection
            usePoints={usePoints}
            setUsePoints={setUsePoints}
            usePointsHighestOrLowest={usePointsHighestOrLowest}
            setUsePointsHighestOrLowest={setUsePointsHighestOrLowest}
          />

          {/* Winners & Player Points section */}
          {usePoints ? (
            formik.values.players?.map((player) => (
              <Grid key={`points-${player}`} item xs={12}>
                <ListItemText primary={getPlayerDisplayName(player)} />
                <TextField
                  id={`points-${player}`}
                  name={`points.${player}`}
                  label="Points"
                  variant="outlined"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            ))
          ) : (
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="add-play-winners-select-label">Winners</InputLabel>
              <Select
                labelId="add-play-winners-select-label"
                id="add-play-winners-select"
                name="winners"
                label="Winners"
                multiple
                fullWidth
                value={formik.values.winners}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                renderValue={(selected) => selected?.map(getPlayerDisplayName).join(", ")}
              >
                {players.filter(player => formik.values.players?.indexOf(player.id) > -1).map((player) => (
                  <MenuItem
                    key={player.id}
                    value={player.id}
                    style={getStyles(player.firstname, formik.values.players, theme)}
                  >
                    <Checkbox checked={formik.values.winners?.indexOf(player.id) > -1} />
                    <ListItemText primary={player.firstname} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          )}

          <Grid item xs={12} md={6}>
            <Button color="primary" variant="outlined" fullWidth type="submit">
              Add
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button color="error" variant="text" fullWidth onClick={() => navigate('/games')}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

