import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { v4 as uuidv4 } from 'uuid';
import { remove, update } from '../utils/array';
import { defaultGames } from './gameStore.init';

interface GameState {
  games: Game[];
  addGame: (game: Partial<Game>) => void;
  updateGame: (game: Game) => void;
  removeGame: (game: Game) => void;
  removeGameById: (id: string) => void;
  completeGame: (game: Game) => void;

  active: GamePlay[];
  startPlaying: (play: GamePlay) => Promise<void>;
  addPlay: (play: GamePlay) => Promise<void>;

  finished: GamePlay[];
}

const gameFactory = (game: Partial<Game>): Game => ({
  ...game,
  id: uuidv4()
}) as Game;

export const useGameStore = create(
  persist(
    subscribeWithSelector<GameState>((set) => ({
      games: defaultGames,
      addGame: (game: Partial<Game>) => set((state) => ({ games: [...state.games, gameFactory(game)] })),
      updateGame: (game: Game) => set((state) => ({ games: update(game, state.games) as Game[] })),
      removeGame: (game: Game) => set((state) => ({ games: remove(game, state.games) as Game[] })),
      removeGameById: (id: string) => set((state) => ({ games: remove({ id }, state.games) as Game[] })),
      completeGame: (game: Game) => set((state) => ({ games: update({ ...game, completed: true } as Game, state.games) as Game[] })),

      active: [],
      startPlaying: async (play: GamePlay) => set((state) => ({ active: [...state.active, play]})),
      addPlay: async (play: GamePlay) => set((state) => ({ finished: [...state.finished, play]})),

      finished: []
    })),
    {
      name: 'tabletopsidekick_games'
    }
  )
);
