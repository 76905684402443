
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Grid, TextField, Typography } from "@mui/material";

import { useGameStore } from "../../../../src/stores/gameStore";
import { useLoaderData, useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
  name: yup.string().required()
});

export function EditGameSettings() {

  const navigate = useNavigate();

  const addGame = useGameStore((state) => state.addGame);
  const updateGame = useGameStore((state) => state.updateGame);

  const game = useLoaderData() as Game | undefined;

  const formik = useFormik({
    initialValues: {
      name: game?.name,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if(game){
        updateGame({
          ...game,
          name: values.name!
        });
      } else {
        addGame({
          name: values.name
        });
      }

      navigate('/games');
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6" gutterBottom>
          { game ? 'Edit' : 'Add' } Game
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="name"
              name="name"
              label="Name"
              margin="normal"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Button color="primary" variant="outlined" fullWidth type="submit">
              Save
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button color="error" variant="text" fullWidth onClick={() => navigate('/games')}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
