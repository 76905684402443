
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { v4 as newId } from 'uuid';
import { Autocomplete, Button, Checkbox, Grid, ListItemText, MenuItem, OutlinedInput, Select, TextField, Theme, Typography, useTheme } from "@mui/material";

import { useGameStore } from "../../../stores/gameStore";
import { useNavigate } from 'react-router-dom';
import { usePlayerStore } from '../../../stores/playerStore';
import { toLookup } from '../../../utils/array';

const validationSchema = yup.object({
  game: yup.object().required(),
  players: yup.array().of(yup.string()).required()
});

function getStyles(name: string, playerName: string[], theme: Theme) {
  return {
    fontWeight:
      playerName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function StartNewGame() {
  const theme = useTheme();
  const navigate = useNavigate();

  const games = useGameStore((state) => state.games);
  const startPlaying = useGameStore((state) => state.startPlaying);

  const players = usePlayerStore((state) => state.players);
  const playersLookup: { [key: string]: Player} = toLookup(players) as { [key: string]: Player};
  const getPlayerDisplayName = (playerId: string) => playersLookup[playerId].firstname;

  // TODO: set default values for form based on query params
  const formik = useFormik({
    initialValues: {
      game: null as Game | null,
      players: [] as string[]
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const playId = newId();
      const play = {
        playId,
        gameId: values.game!.id,
        playerIds: values.players
      };

      await startPlaying(play);

      navigate('/games/playing/'+ playId);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant="h6" gutterBottom>
          Start Playing a New Game
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              autoComplete
              clearOnBlur={false}
              options={games}
              getOptionLabel={ game => game.name }
              getOptionKey={ game => game.id }
              value={formik.values.game}
              onChange={(event: any, newValue: Game | null) => {
                formik.setFieldValue("game", newValue);
              }}
              onInputChange={() => { formik.setTouched({ game: true }) }}
              onBlur={formik.handleBlur}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="Game"
                  error={formik.touched.game && Boolean(formik.errors.game)}
                  helperText={formik.touched.game && formik.errors.game}
                  variant="outlined"
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              id="players"
              name="players"
              multiple
              fullWidth
              value={formik.values.players}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              input={<OutlinedInput />}
              label="Players"
              renderValue={(selected) => selected?.map(getPlayerDisplayName).join(", ")}
            >
              {players.map((player) => (
                <MenuItem
                  key={player.id}
                  value={player.id}
                  style={getStyles(player.firstname, formik.values.players, theme)}
                >
                  <Checkbox checked={formik.values.players?.indexOf(player.id) > -1} />
                  <ListItemText primary={player.firstname} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button color="primary" variant="outlined" fullWidth type="submit">
              Start
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button color="error" variant="text" fullWidth onClick={() => navigate('/games')}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
