import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { usePlayerStore } from "../../../stores/playerStore";
import { toLookup } from '../../../utils/array';
import { useParams } from "react-router-dom";
import { useGameStore } from "../../../stores/gameStore";
//import { useNavigate } from "react-router-dom";

export function Playing() {

    const params = useParams();

    const playId = params['id'];

    const players = usePlayerStore((state) => state.players);
    const playerLookup = toLookup(players) as { [key: string]: Player };

    const games = useGameStore((state) => state.games);
    const activePlays = useGameStore((state) => state.active);
    const play = activePlays.find(p => p.playId == playId);

    // TODO: Initialize resources based on game scoring settings
    const resources: any[] = [];

    let columns: GridColDef[] = [
      {
        field: 'resources',
        headerName: 'Resources',
        width: 90
      },
    ];

    play?.playerIds.forEach(playerId => {
      columns.push({
        field: playerLookup[playerId]?.firstname,
        headerName: playerLookup[playerId]?.firstname,
        width: 150
      })
    });

    return (
      <>
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={resources}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
          />
        </Box>
      </>
    );
  }

