import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import { useNavigate } from "react-router-dom";
import { useGameStore } from "../../../stores/gameStore";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId } from "@mui/x-data-grid";
import { groupBy } from '../../../utils/array';
import { ListHeader } from '../../../components/ListHeader';
import { ListBox } from '../../../components/ListBox';

export function GamesList(){

  const navigate = useNavigate();

  const games = useGameStore((state) => state.games);

  const removeGameById = useGameStore((state) => state.removeGameById);
  const finishedGames = useGameStore((state) => state.finished);
  const finishedGamesGroupings = groupBy(finishedGames, 'gameId');

  const addGameClick = () => {
    navigate('/games/edit');
  }

  const handleDeleteClick = (id: GridRowId) => () => {
    removeGameById(id.toString());
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      editable: true,
    },
    {
      field: 'plays',
      valueGetter: ({ row }) => finishedGamesGroupings[row.id]?.length || 0,
      headerName: '# of Plays',
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 120,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<GroupAddIcon  />}
            title="Add a Play"
            label="Add Play"
            className="textPrimary"
            onClick={() => {
                navigate(`/games/add-play?game=${id}`)
              }
            }
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            title="Edit Game"
            label="Edit"
            className="textPrimary"
            onClick={() => navigate(`/games/edit/${id}`)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            title="Delete Game"
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <ListBox>
        <ListHeader
          handleAddClick={addGameClick}
          label={'Games'}
        />
        <DataGrid
          rows={games}
          columns={columns}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </ListBox>
    </>
  );
}
