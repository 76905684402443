import React from "react";
import { AppBar, Box, Button, IconButton, Link, Toolbar, Typography } from "@mui/material";
import CottageIcon from '@mui/icons-material/Cottage';
import CasinoIcon from '@mui/icons-material/Casino';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsIcon from '@mui/icons-material/Settings';
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";
import { useAuthStore } from "../stores/authStore";
import { UserDisplay } from "./UserDisplay";

export function AppMenu() {
  const signedIn = useAuthStore(state => state.user !== null);
  const login = useAuthStore(state => state.login);
  const logout = useAuthStore(state => state.logout);

  return (
    <Box sx={{ flexGrow: 1, color: 'text.primary', fontSize: 24, fontWeight: 'medium' }}>
      <AppBar position="static" sx={{ flexGrow: 1 }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="home"
            sx={{ mr: 2 }}
            component={RouterLink}
            to="/"
          >
            <CottageIcon />
          </IconButton>

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="games"
            sx={{ mr: 2 }}
            component={RouterLink}
            to="/games"
          >
            <CasinoIcon />
          </IconButton>

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="players"
            component={RouterLink}
            to="/players"
          >
            <GroupsIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "end" }}>
            <UserDisplay
              signedIn={signedIn}
              login={login}
              logout={logout}
            />
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="settings"
              component={RouterLink}
              to="/settings"
            >
              <SettingsIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
