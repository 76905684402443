export class WinnerCalculatorService {
  calculateByPoints(playerscores: {[player: string]: number }, highestOrLowest: 'highest' | 'lowest') {

    let locator = 0;
    if(highestOrLowest === 'highest') {
      locator = Math.max(...Object.values(playerscores));
    } else {
      locator = Math.min(...Object.values(playerscores));
    }

    return Object.keys(playerscores).filter(playerId => playerscores[playerId] == locator);
  }
}
