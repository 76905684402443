import { useSettingsStore } from "../../../stores/settingsStore";
import { Box, FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";

export function SettingsList() {
  const featureFlags = useSettingsStore((state) => state.featureFlags);
  const setFeatureFlagEnabled = useSettingsStore((state) => state.setFeatureFlagEnabled);
  return (
    <Box sx={{ pt: 3 }}>
      <Typography variant="h5">Experimental Features</Typography>

      { featureFlags?.custom_scoring_experimental ?
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={featureFlags.custom_scoring_experimental.enabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFeatureFlagEnabled({ flag: featureFlags.custom_scoring_experimental, enabled: event.target.checked })}
              />
            }
            key={featureFlags.custom_scoring_experimental.id}
            label={featureFlags.custom_scoring_experimental.description} />
        </FormGroup>
        : null
      }
    </Box>
  );
}
