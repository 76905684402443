import React from 'react';
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";
import { Layout } from './components/Layout';
import { Outlet } from 'react-router-dom';

export function Root() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout>
        <Outlet />
      </Layout>
    </ThemeProvider>
  )
}
