import React from 'react';
import { Container } from 'reactstrap';
import { AppMenu } from './AppMenu';

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div>
      <AppMenu />
      <Container tag="main">
        {children}
      </Container>
    </div>
  );
}
