import React from 'react';
import { Box } from '@mui/material';

export function ListBox({ children }: { children: React.ReactNode }) {
  return (
    <Box sx={{ paddingTop: 3 }}>
      {children}
    </Box>
  );
}
